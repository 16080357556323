import React from 'react';
import MainLayout from './components/MainLayout';
import Footer from './components/Footer';
import EmailSubscription from './components/EmailSubscription';
import SuggestionsBox from './components/SuggestionsBox';

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-indigo-100 to-white">
      <div className="flex-grow py-24 px-4 sm:px-6 lg:px-8">
        <MainLayout />
      </div>
      <Footer />
    </div>
  );
}

export default App;