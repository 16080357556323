import React from 'react';
import { Loader2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

// NOTE: If I wanted to combine the formatted content into a component here with the exercise_generator
// ... I absolutely could (as described here: https://claude.ai/chat/7f7fe2b5-835d-42e5-9e66-dfa9d782178b)
// ... However, I've decided not to for now because the structure of the story output may diverge.


const FormattedStory = ({ storyPart, partNumber }) => {
  const formattedContent = `## Part ${partNumber}\n${storyPart.text_content}`;

  return (
    <div className="mb-8">
      {storyPart.image && partNumber === 1 && (
        <img 
          src={storyPart.image} 
          alt={`Story illustration for Part ${partNumber}`} 
          className="w-full h-auto rounded-lg shadow-md mb-6"
        />
      )}
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => <h1 className="text-3xl font-bold mt-8 mb-4 text-indigo-800" {...props} />,
          h2: ({ node, ...props }) => <h2 className="text-2xl font-bold mt-6 mb-3 text-indigo-700" {...props} />,
          h3: ({ node, ...props }) => <h3 className="text-xl font-semibold mt-5 mb-2 text-indigo-600" {...props} />,
          p: ({ node, ...props }) => <p className="mb-4 text-gray-800 leading-relaxed" {...props} />,
          ul: ({ node, ...props }) => <ul className="list-disc pl-5 mb-4 text-gray-800" {...props} />,
          ol: ({ node, ...props }) => <ol className="list-decimal pl-5 mb-4 text-gray-800" {...props} />,
          li: ({ node, ...props }) => <li className="mb-2" {...props} />,
          strong: ({ node, ...props }) => <strong className="font-semibold text-gray-900" {...props} />,
          em: ({ node, ...props }) => <em className="italic text-gray-900" {...props} />
        }}
      >
        {formattedContent}
      </ReactMarkdown>
      {/* {storyPart.image && (
        <img 
          src={storyPart.image} 
          alt={`Story illustration for Part ${partNumber}`} 
          className="w-full h-auto rounded-lg shadow-md mt-4"
        />
      )} */}
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-8">
    <Loader2 className="h-10 w-10 animate-spin text-indigo-500" />
    <span className="ml-3 text-xl font-medium text-gray-700">Generating story (may take a minute or so)...</span>
  </div>
);

export default function StoryDisplay({ hasGenerated, loading, error, generatedStory }) {
  if (!hasGenerated) {
    return null;
  }

  return (
    <div className="mt-12 shadow-lg rounded-lg overflow-hidden border-2 border-indigo-200">
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div className="p-6 bg-red-50 border-l-4 border-red-500">
          <p className="text-red-700 text-lg font-medium">{error}</p>
        </div>
      ) : generatedStory && generatedStory.length > 0 ? (
        <div className="p-8">
          <h2 className="text-3xl font-extrabold border-b-2 border-indigo-200 pb-4 mb-6">
            Generated Story
          </h2>
          <div className="bg-white p-6 rounded-lg">
            {generatedStory.map((storyPart, index) => (
              <FormattedStory key={index} storyPart={storyPart} partNumber={index + 1} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
