import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_FLASK_BACKEND_URL
  : 'http://127.0.0.1:8000';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const generateExercise = (data) => api.post('/exercise/generate', data);
export const generateStory = (data) => api.post('/story/generate', data);
export const subscribeEmail = (email) => api.post('/subscribe', { email });
export const submitSuggestion = (suggestion) => api.post('/suggestion', { suggestion });

// NOTE: subscribe email functionality actually implemented in Footer.js and doesn't
// use the useApi hook which imports from here

export default {
  generateExercise,
  generateStory,
  subscribeEmail,
  submitSuggestion,
};