import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import useApi from '../../hooks/useApi';
import StoryDisplay from './FormattedStory';

const StoryGenerator = () => {
  const [generatedStory, setGeneratedStory] = useState([]);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [formData, setFormData] = useState({
    storyTopic: '',
    learningTakeaway: '',
    age: '',
    additionalInfo: ''
  });

  const { loading, error, callApi } = useApi();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasGenerated(true);

    const translatedFormData = {
      story_topic: formData.storyTopic,
      learning_takeaway: formData.learningTakeaway,
      student_age: parseInt(formData.age, 10),
      additional_info: formData.additionalInfo
    };

    try {
      const data = await callApi('generateStory', JSON.stringify(translatedFormData));
      setGeneratedStory(data.story_content);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-8">
          <div>
            <label htmlFor="storyTopic" className="block text-2xl font-bold text-gray-900 mb-4">
              I want a story about:
            </label>
            <input
              type="text"
              id="storyTopic"
              name="storyTopic"
              required
              value={formData.storyTopic}
              onChange={handleInputChange}
              className="mt-1 block w-full border-2 border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-lg"
              placeholder="e.g. A curious cat exploring a magical forest"
            />
          </div>

          <div>
            <label htmlFor="learningTakeaway" className="block text-2xl font-bold text-gray-900 mb-4">
              With the following learning point(s):
            </label>
            <input
              type="text"
              id="learningTakeaway"
              name="learningTakeaway"
              required
              value={formData.learningTakeaway}
              onChange={handleInputChange}
              className="mt-1 block w-full border-2 border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-lg"
              placeholder="e.g. The importance of curiosity and friendship"
            />
          </div>

          <div>
            <label htmlFor="age" className="block text-2xl font-bold text-gray-900 mb-4">
              Suitable for age:
            </label>
            <input
              type="number"
              id="age"
              name="age"
              min={3}
              max={18}
              required
              value={formData.age}
              onChange={handleInputChange}
              className="mt-1 block w-full border-2 border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-lg"
              placeholder="Enter age"
            />
          </div>
        </div>

        <div className="space-y-4 mt-8 pt-4 border-t border-gray-200">
          <button
            type="button"
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="flex items-center text-indigo-600 hover:text-indigo-500 focus:outline-none transition duration-150 ease-in-out"
          >
            {showAdvanced ? (
              <>
                <ChevronUpIcon className="h-5 w-5 mr-2" />
                Hide additional options
              </>
            ) : (
              <>
                <ChevronDownIcon className="h-5 w-5 mr-2" />
                Show additional options
              </>
            )}
          </button>

          {showAdvanced && (
            <div>
              <label htmlFor="additionalInfo" className="block text-lg font-medium text-gray-700 mb-2">
                Additional Information:
              </label>
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                rows={3}
                value={formData.additionalInfo}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Any additional details or preferences for the story"
              />
            </div>
          )}

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full sm:w-auto px-6 py-3 border border-transparent rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out text-lg font-medium"
            >
              Generate Story
            </button>
          </div>
        </div>
      </form>
      
      <StoryDisplay 
        hasGenerated={hasGenerated}
        loading={loading}
        error={error}
        generatedStory={generatedStory}
      />
      
    </div>
  );
};

export default StoryGenerator;