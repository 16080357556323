import React, { useState } from 'react';
import { X } from 'lucide-react';
import useApi from '../hooks/useApi';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const { loading, error, callApi } = useApi();

  // NOTE: the handle subscribe functionality should actually be in the useApi hook
  // but throwing an error / less granular control available 
  const API_URL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_FLASK_BACKEND_URL
    : 'http://127.0.0.1:8000';

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/subscribe`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.status === 201) {
        setStatus('success');
        setMessage("You've been successfully subscribed to our updates!");
      } else if (response.status === 409) {
        setStatus('info');
        setMessage('This email is already subscribed.');
      } else {
        throw new Error('Subscription failed');
      }
      
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
      setMessage('There was an error subscribing to updates. Please try again.');
    }
    setIsMessageVisible(true);
  };

  const handleSuggest = async (e) => {
    e.preventDefault();
    try {
      const data = await callApi('submitSuggestion', JSON.stringify({ suggestion }));
      setStatus('success');
      setMessage("Thank you! Your suggestion has been submitted successfully.");
      setSuggestion('');
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
      setMessage('There was an error submitting your suggestion. Please try again.');
    }
    setIsMessageVisible(true);
  };

  return (
    <footer className="bg-white shadow-lg mt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">        
          <div className="w-full md:w-1/2">
            <h2 className="text-lg font-semibold text-gray-900 mb-2">Sign up for updates</h2>
            <form onSubmit={handleSubscribe} className="flex">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                type="submit"
                className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Subscribe
              </button>
            </form>
          </div>
          <div className="w-full md:w-1/2">
            <h2 className="text-lg font-semibold text-gray-900 mb-2">Suggestion?</h2>
            <form onSubmit={handleSuggest} className="flex">
              <input
                type="text"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                placeholder="Enter your suggestion"
                required
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                type="submit"
                className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        {isMessageVisible && (
          <div className={`mt-4 p-4 rounded-md relative ${
            status === 'success' ? 'bg-green-50 text-green-800' :
            status === 'info' ? 'bg-blue-50 text-blue-800' :
            'bg-red-50 text-red-800'
          }`}>
            <button
              onClick={() => setIsMessageVisible(false)}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              aria-label="Close"
            >
              <X size={20} />
            </button>
            <p className="font-medium">{status === 'success' ? 'Success' : status === 'info' ? 'Info' : 'Error'}</p>
            <p>{message}</p>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;