import React from 'react';
import { Loader2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

const FormattedExercise = ({ exercise }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mt-6 mb-3 text-indigo-800" {...props} />,
        h2: ({ node, ...props }) => <h2 className="text-xl font-bold mt-5 mb-2 text-indigo-700" {...props} />,
        h3: ({ node, ...props }) => <h3 className="text-lg font-semibold mt-4 mb-2 text-indigo-600" {...props} />,
        p: ({ node, ...props }) => <p className="mb-4 text-gray-800" {...props} />,
        ul: ({ node, ...props }) => <ul className="list-disc pl-5 mb-4 text-gray-800" {...props} />,
        ol: ({ node, ...props }) => <ol className="list-decimal pl-5 mb-4 text-gray-800" {...props} />,
        li: ({ node, ...props }) => <li className="mb-2" {...props} />,
        strong: ({ node, ...props }) => <strong className="font-semibold text-gray-900" {...props} />,
        em: ({ node, ...props }) => <em className="italic text-gray-900" {...props} />
      }}
    >
      {exercise}
    </ReactMarkdown>
  );
};

const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-8">
    <Loader2 className="h-10 w-10 animate-spin text-indigo-500" />
    <span className="ml-3 text-xl font-medium text-gray-700">Generating exercise...</span>
  </div>
);

export default function ExerciseDisplay({ hasGenerated, loading, error, generatedExercise }) {
  if (!hasGenerated) {
    return null;
  }

  return (
    <div className="mt-12 shadow-lg rounded-lg overflow-hidden border-2 border-indigo-200">
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div className="p-6 bg-red-50 border-l-4 border-red-500">
          <p className="text-red-700 text-lg font-medium">{error}</p>
        </div>
      ) : generatedExercise ? (
        <div className="p-8">
          <h2 className="text-2xl font-extrabold border-indigo-200">
            Suggested Exercise
          </h2>
          <div className="bg-white p-6 rounded-lg">
            <FormattedExercise exercise={generatedExercise} />
          </div>
        </div>
      ) : null}
    </div>
  );
}