import React from 'react';

const AdvancedOptions = ({ formData, handleInputChange, handleResourceChange }) => {
  return (
    <div className="mt-6 space-y-6">
      <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
        <div className="w-full sm:w-1/2">
          <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
            Exercise Duration
          </label>
          <select
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleInputChange}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">Select duration</option>
            <option>15-30 minutes</option>
            <option>30-60 minutes</option>
            <option>1-2.5 hours</option>
            <option>2.5-5 hours</option>
            <option>Multi-day project (1-3 days)</option>
          </select>
        </div>
        <div className="w-full sm:w-1/2">
          <label htmlFor="difficulty" className="block text-sm font-medium text-gray-700 mb-1">
            Difficulty Level
          </label>
          <select
            id="difficulty"
            name="difficulty"
            value={formData.difficulty}
            onChange={handleInputChange}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">Select difficulty</option>
            <option>Beginner</option>
            <option>Intermediate</option>
            <option>Advanced</option>
          </select>
        </div>
      </div>

      <div>
        <span className="block text-sm font-medium text-gray-700 mb-2">
          Resources Available
        </span>
        <div className="grid grid-cols-2 gap-y-2 sm:grid-cols-3 gap-x-4">
          {Object.keys(formData.resources).map((resource) => (
            <div key={resource} className="flex items-center">
              <input
                id={resource}
                type="checkbox"
                checked={formData.resources[resource]}
                onChange={() => handleResourceChange(resource)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={resource} className="ml-2 block text-sm text-gray-900">
                {resource}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div>
        <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700 mb-1">
          Additional Information
        </label>
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          rows={3}
          value={formData.additionalInfo}
          onChange={handleInputChange}
          className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Any other considerations?"
        />
      </div>
    </div>
  );
};

export default AdvancedOptions;