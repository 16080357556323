import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import AdvancedOptions from './AdvancedOptions';
import ExerciseDisplay from './FormattedExercise';
import useApi from '../../hooks/useApi';


const ExerciseGenerator = () => {
  const [generatedExercise, setGeneratedExercise] = useState('');
  const [hasGenerated, setHasGenerated] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [formData, setFormData] = useState({
    age: '',
    subject: '',
    difficulty: '',
    duration: '',
    resources: {
      "Computer/Internet": false,
      "Art Supplies": false,
      "Outdoor Space": false,
      "Basic Lab Equipment": false,
      "Library Access": false
    },
    additionalInfo: ''
  });

  const { loading, error, callApi } = useApi();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleResourceChange = (resource) => {
    setFormData(prev => ({
      ...prev,
      resources: { ...prev.resources, [resource]: !prev.resources[resource] }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasGenerated(true);

    const translatedFormData = {
      student_age: formData.age,
      subject_topic: formData.subject,
      difficulty: formData.difficulty,
      duration: formData.duration,
      resources: formData.resources ? Object.keys(formData.resources).filter(key => formData.resources[key]) : [],
      additional_info: formData.additionalInfo
    };

    try {
      const data = await callApi('generateExercise', JSON.stringify(translatedFormData));
      setGeneratedExercise(data.exercise_content);
      // resetForm();
      // return response.json();
    } catch (err) {
      // setError('Failed to generate exercise. Please try again.');
      console.error('Error:', err);
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-8">
          <div>
            <label htmlFor="subject" className="block text-2xl font-bold text-gray-900 mb-4">
              I want to learn about:
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              required
              value={formData.subject}
              onChange={handleInputChange}
              className="mt-1 block w-full border-2 border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-lg"
              placeholder="e.g. Math, Solar System, Shakespeare"
            />
          </div>

          <div>
            <label htmlFor="age" className="block text-2xl font-bold text-gray-900 mb-4">
              Suitable for age:
            </label>
            <input
              type="number"
              id="age"
              name="age"
              min={3}
              max={18}
              required
              value={formData.age}
              onChange={handleInputChange}
              className="mt-1 block w-full border-2 border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-lg"
              placeholder="Enter age"
            />
          </div>
        </div>

        <div className="space-y-4 mt-8 pt-4 border-t border-gray-200">
          <button
            type="button"
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="flex items-center text-indigo-600 hover:text-indigo-500 focus:outline-none transition duration-150 ease-in-out"
          >
            {showAdvanced ? (
              <>
                <ChevronUpIcon className="h-5 w-5 mr-2" />
                Hide additional options
              </>
            ) : (
              <>
                <ChevronDownIcon className="h-5 w-5 mr-2" />
                Show additional options
              </>
            )}
          </button>

          {showAdvanced && (
            <AdvancedOptions
              formData={formData}
              handleInputChange={handleInputChange}
              handleResourceChange={handleResourceChange}
            />
          )}

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full sm:w-auto px-6 py-3 border border-transparent rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out text-lg font-medium"
            >
              Generate Exercise
            </button>
          </div>
        </div>
      </form>
      
      <ExerciseDisplay
        hasGenerated={hasGenerated}
        loading={loading}
        error={error}
        generatedExercise={generatedExercise}
      />
    </div>
  );
};

export default ExerciseGenerator;